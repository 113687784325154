import Anders from "../Content/Main/Employees/Anders";
import Eivind from "../Content/Main/Employees/Eivind";
import Solve from "../Content/Main/Employees/Solve";
import Info from "../Content/Main/Info";

const Ansatte = () => {
    return (
      <div>
        <div>
          <Anders />
          <Eivind />
          <Solve />
        </div>
        <Info />
      </div>
    );
  };
  
  export default Ansatte;