import React, { useRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import solveKoder from "../../Images/photos/solveKoder.jpg";
import ArrowRight from "../../Images/ArrowRight";

const Intro = () => {
    const textRef2 = useRef(null);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setOffset(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (textRef2.current) {
            textRef2.current.style.transform = `translateY(${-offset * 0.7}px)`;
        }
    }, [offset]);

    const goCode = `
package logger

import "testing"

// INFO: Table driven testing
func TestEntry_string(t *testing.T) {
\t// Input struct
\ttype fields struct {
\t\tMessage  string
\t\tSeverity Severity
\t}
\ttests := []struct {
\t\tname    string // INFO: test case name
\t\tfields  fields // INFO: input
\t\twant    string // INFO: expected output
\t\twantErr bool   // INFO: exepcted error scenario
\t}{
\t\t{
\t\t\t"given 'test message' and INFO severity produces test message and INFO severity in json format",
\t\t\tfields{"test message", Info},
\t\t\t"{\\"message\\":\\"test message\\",\\"severity\\":\\"INFO\\"}",
\t\t\tfalse,
\t\t},
\t\t{
\t\t\t"given empty message and severity still produces message and INFO severity",
\t\t\tfields{"", Severity("")},
\t\t\t"{\\"message\\":\\"logger: No message provided\\",\\"severity\\":\\"INFO\\"}",
\t\t\tfalse,
\t\t},
\t\t// TODO: Add test cases.
\t}
\tfor _, tt := range tests {
\t\tt.Run(tt.name, func(t *testing.T) {
\t\t\te := Entry{
\t\t\t\tMessage:  tt.fields.Message,
\t\t\t\tSeverity: tt.fields.Severity,
\t\t\t}
\t\t\tgot, err := e.string()
\t\t\tif (err != nil) != tt.wantErr {
\t\t\t\tt.Errorf("Entry.string() error = %v, wantErr %v", err, tt.wantErr)
\t\t\t\treturn
\t\t\t}
\t\t\tif got != tt.want {
\t\t\t\tt.Errorf("Entry.string() = %v, want %v", got, tt.want)
\t\t\t}
\t\t})
\t}
}
`;

    return (
        <div>
            <div className="flex justify-start mt-8 md:mt-16 mr-2">
                <div className="text-primary-black md:w-2/5">
                    <div className="flex flex-row">
                        <h2 className="pt-1 font-medium text-3xl md:text-5xl">Bak skjermen</h2>
                        <div className="rotate-90 pl-2 -mx-2">
                            <ArrowRight color={!isMobile ? "#FBF5C1" : "#000000"} />
                        </div>
                    </div>
                    <div className="pb-4 pt-16 md:pt-4 font-medium text-xl md:text-2xl">
                        Vi jobber i bakgrunnen slik at alt du ser fungerer. 
                        Sikre betalingsløsninger, universell utforming, programvare- arkitektur og IT-sikkerhet er bare noen av tingene vi kan hjelpe deg med. 
                    </div>
                </div>
            </div>
            <div className="relative h-screen md:h-auto max-h-96 md:max-h-none overflow-hidden">
                <img className="w-full h-full object-cover" src={solveKoder} loading="lazy" alt="Sølve koder" />
                <div
                    ref={textRef2}
                    className="absolute top-2/3 left-1/2 transform -translate-x-1/2 text-white text-base pr-2 md:text-3xl font-semibold whitespace-pre-wrap"
                >
                    {goCode}
                </div>
            </div>
        </div>
    );
};

export default Intro;
