import { useState } from "react";
import {isMobile} from 'react-device-detect';
import drawSolvedBig from "../../../Images/employees/drawSolveBig.png"
import solveBig from "../../../Images/employees/solveBig.png"



const Solve = () => {
    const [imgHover, setImgHover] = useState(false);
    return (
        <div className="flex flex-col mt-20 md:flex-row">
            <div className="pt-2 md:w-3/5">
                <img className="object-contain" onMouseOver={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)} src={imgHover || isMobile ? solveBig : drawSolvedBig} alt="Bilde av Sølve" />
            </div>
            <div className="font-medium text-xl md:text-2xl pl-2 md:pl-10 md:w-2/5">
                <h2 className="pt-1 font-medium text-3xl md:text-5xl">Sølve</h2>
                <div className="mb-10 mt-12">Backend utvikler</div>
                <div className="pt-6"><span className="font-bold">Bakgrunn:</span>  Bachelor i programmering og nettverk fra UIO</div>
                <div className="pt-6"><span className="font-bold">Ting jeg kan:</span>  Cloud, system, event og software- architecture, REST, og IT-sikkerhet i alle ledd (IAM, OAuth2, DB, brannmur, VPC, ID-porten og Maskinporten).</div>
                <div className="pt-6"><span className="font-bold">Kodespråk:</span> Java, Kotlin, Go, C, Rust, Lua, Bash og Python</div>
                <div className="pt-6"><span className="font-bold">Hobbyer:</span>Hjemmeautomasjon, 3D printing og gaming. Jeg liker generelt å finne ut hvordan det meste funker i detalj.</div>
            </div>
        </div>     
    );
};

export default Solve;