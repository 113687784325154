const Info = () => {
    return (
        <div className="mt-28 p-8 mb-16 ml-4 mr-4 md:ml-20 md:mr-20 border-4 border-primary-black">
            <div className="flex flex-col text-xl md:text-2xl md:flex-row md:justify-around">
                <div className="basis-1/4">
                    <div className="pt-6 pb-6 md:pl-10">
                        <h3 className="font-bold">VIKODER</h3>
                        <div>i Hamar og Oslo området</div>
                    </div>
                </div>
                <div className="basis-1/4">
                    <div className="pt-6 pb-6">
                        <h3 className="font-bold">Vi gir deg mer</h3>
                        <div className="pr-8">av pengene du tjener selv</div>
                    </div>    
                </div>
                <div className="basis-1/4">
                    <div className="pt-6 pb-6">
                        <h3 className="font-bold">Vi kan ta en kaffe</h3>
                        <div>hvis du også liker å jobbe i bakgrunnen</div>
                    </div>
                </div>
                <div className="basis-1/4">
                    <div className="pt-6 pb-6 pr-10 ">
                        <h3 className="font-bold">Vi koder for deg</h3>
                        <div>om du trenger hjelp til det</div>
                    </div>
                </div>
            </div>
        </div>  
    );
};

export default Info;