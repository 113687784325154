import { useState } from "react";
import {isMobile} from 'react-device-detect';
import drawAndersBig from "../../../Images/employees/drawAndersBig.png"
import andersBig from "../../../Images/employees/andersBig.png"



const Anders = () => {
    const [imgHover, setImgHover] = useState(false);
    return (
        <div className="flex flex-col mt-20 md:flex-row">
            <div className="pt-2 md:w-3/5">
                <img className="object-contain" onMouseOver={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)} src={imgHover || isMobile ? andersBig : drawAndersBig} alt="Bilde av Anders" />
            </div>
            <div className="font-medium text-xl md:text-2xl pl-2 md:pl-10 md:w-2/5">
                <h2 className="pt-1 font-medium text-3xl md:text-5xl">Anders</h2>
                <div className="mb-10 mt-12">Backend utvikler</div>
                <div className="pt-6"><span className="font-bold">Bakgrunn:</span>  Master i Artificial Intelligence fra NTNU</div>
                <div className="pt-6"><span className="font-bold">Ting jeg kan:</span> Software architecture og design, REST API, Kafka, Spring</div>
                <div className="pt-6"><span className="font-bold">Kodespråk:</span> C++, Java, Kotlin og Python</div>
                <div className="pt-6"><span className="font-bold">Hobbyer:</span> Gaming og styrketrening. På sommeren forsøker jeg å få til noen teltturer. Interesserer meg for teknologi, fysikk og (geo)politikk.</div>
            </div>
        </div>
        
        
       
    );
};

export default Anders;