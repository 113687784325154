import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import Template from "./Content/Template";
import Ansatte from "./Pages/Ansatte";
import FrontPage from "./Pages/FrontPage";
import Blog from "./Pages/Blog";

function App() {
  return (
    <div className="min-h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Template />}>
            <Route index element={<FrontPage />} />
            <Route path="ansatte" element={<Ansatte />} />
            <Route path="blog" element={<Blog />} />
            <Route path="*" element={<h1>404</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Outlet />
    </div>
  );
}

export default App;
