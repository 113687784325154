import { useState } from "react";
import drawSolveSmall from "../../Images/employees/drawSolveSmall.png"
import solveSmall from "../../Images/employees/solveSmall.png"
import drawEivindSmall from "../../Images/employees/drawEivindSmall.png"
import eivindSmall from "../../Images/employees/eivindSmall.png"
import drawAndersSmall from "../../Images/employees/drawAndersSmall.png"
import andersSmall from "../../Images/employees/andersSmall.png"



const WorkForUs = () => {
    const [solveHover, setSolveHover] = useState(false);
    const [eivindHover, setEivindHover] = useState(false);
    const [andersHover, setAndersHover] = useState(false);
    return (
            <div className="mt-28 flex flex-col-reverse md:flex-row">
                <div>
                    <img onMouseOver={() => setSolveHover(true)} onMouseLeave={() => setSolveHover(false)} src={solveHover ? solveSmall : drawSolveSmall} alt="Bilde av Sølve" />
                </div>
                <div>
                    <img onMouseOver={() => setEivindHover(true)} onMouseLeave={() => setEivindHover(false)} src={eivindHover ? eivindSmall : drawEivindSmall} alt="Bilde av Eivind" />
                </div>
                <div>
                    <img onMouseOver={() => setAndersHover(true)} onMouseLeave={() => setAndersHover(false)} src={andersHover ? andersSmall : drawAndersSmall} alt="Bilde av Anders" />
                </div>
                <div className="flex flex-col md:w-1/3 md:pl-4">
                    <h2 className="pt-2 font-medium text-3xl md:text-5xl">Vil du jobbe med oss?</h2>
                    <div className="font-medium text-xl md:text-2xl">
                        <p className="pt-2">Gøy! Vi er på utkikk etter nye kollegaer i Oslo og Hamar området. Du kan godt bo i Oslo og jobbe hjemmefra, men vi vil gjerne at du stikker innom for kaffeslabberas og middag innimellom.</p>
                        <p className="pt-2">Hos oss skal alle ha en god lønn.  80% av fakturagrunnlaget går til deg 20% går til investering i nye ansatte, gøy og goder for alle.</p>
                    </div>
                </div>
            </div>  
    );
};

export default WorkForUs;