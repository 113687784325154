import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import Door from "../Content/Main/Door"
import Intro from "../Content/Main/Intro"
import AboutVikoder from "../Content/Main/AboutVikoder";
import Customers from "../Content/Main/Customers";
import WorkForUs from "../Content/Main/WorkForUs";
import Info from "../Content/Main/Info";
import ContactUs from "../Content/Main/ContactUs";

const FrontPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const hash = window.location.hash;
      const el = hash && document.getElementById(hash.substring(1));
      if (el) {    
          el.scrollIntoView({behavior: "smooth"})
          setTimeout(() => {
            navigate("/", { replace: true , preventScrollReset: true});
          }, "2000");
        }
      }, [location])

    return (
      <div className="p-4 md:p-0">
        <Door />
        <Intro />
        <div id="hvem">
          <AboutVikoder />
        </div>
        <div id="kunder">
          <Customers />
        </div>
        <div id="arbeid">
          <WorkForUs />
        </div>
        <Info />
        <div id="hjelp">
          <ContactUs />
        </div>
      </div>
    );
  };
  
  export default FrontPage;