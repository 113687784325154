import { useState } from "react";
import {isMobile} from 'react-device-detect';
import drawEivindBig from "../../../Images/employees/drawEivindBig.png"
import eivindBig from "../../../Images/employees/eivindBig.png"



const Eivind = () => {
    const [imgHover, setImgHover] = useState(false);
    return (
        <div className="flex flex-col mt-20 md:flex-row">
            <div className="pt-2 md:w-3/5">
                <img className="object-contain" onMouseOver={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)} src={imgHover || isMobile ? eivindBig : drawEivindBig} alt="Bilde av Eivind" />
            </div>
            <div className="font-medium text-xl md:text-2xl pl-2 md:pl-10 md:w-2/5">
                <h2 className="pt-1 font-medium text-3xl md:text-5xl">Eivind</h2>
                <div className="mb-10 mt-12">Frontend utvikler</div>
                <div className="pt-6"><span className="font-bold">Bakgrunn:</span>  Bachelor i informatikk fra NTNU</div>
                <div className="pt-6"><span className="font-bold">Ting jeg kan:</span>  Sikre betalingsløsninger, Vipps implementering, universell utforming</div>
                <div className="pt-6"><span className="font-bold">Kodespråk/Rammeverk:</span> Javascript, React, Typescript, TailwindCSS, Redux</div>
                <div className="pt-6"><span className="font-bold">Hobbyer:</span> Terrengsykling, Gaming, golf, bilpleie og bilracing.</div>
            </div>
        </div>     
    );
};

export default Eivind;