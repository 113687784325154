import { useState } from "react";
import {isMobile} from 'react-device-detect';
import ArrowTopDown from "../../Images/arrow_top_right";

const ContactUs = () => {
    const [coffeShow, setCoffeeShow] = useState(false);
    const [coffeHover, setCoffeHover] = useState(false);
    const [codeShow, setCodeShow] = useState(false);
    const [codeHover, setCodeHover] = useState(false);
    return (
        <div className="mt-28 mb-28 min-h-40">
            <div className="flex flex-col justify-center md:flex-row md:justify-around">
                <div className="min-h-48 min-w-72 mb-4 md:mb-0 md:min-h-full ml-10 md:ml-16">
                    <div>
                        <button
                            className={`${coffeHover ? "bg-primary-black text-primary-yellow" : "bg-primary-yellow text-primary-black"}`}
                            onMouseOver={() => setCoffeHover(true)}
                            onMouseLeave={() => setCoffeHover(false)}
                            onClick={() => {
                                setCoffeeShow(!coffeShow)
                                if (isMobile) {
                                    setCodeHover(false);
                                }
                            }
                        }>
                            <div className="flex flex-row">
                                <h3 className="font-medium text-xl md:text-2xl">Kaffeprat</h3>
                                <div className="pt-2 pl-4">
                                    <ArrowTopDown color={coffeHover ? "#FBF5C1" : "#1C1B1F"}/> 
                                </div>
                            </div>
                        </button>      
                    </div>
                    {coffeShow && (
                        <div className="flex flex-col pt-8">
                            <h3 className="font-bold text-xl md:text-2xl">Eivind Sandell</h3>
                            <div className="font-medium text-xl md:text-2xl underline">eivind.sandell@vikoder.no</div>
                            <div className="font-medium text-xl md:text-2xl">480 55 939</div>
                        </div>
                    )}
                </div>
                <div className="min-h-48 min-w-72 md:min-h-full ml-10 md:ml-16">
                    <div>
                        <button
                            className={`${codeHover ? "bg-primary-black text-primary-yellow" : "bg-primary-yellow text-primary-black"}`}
                            onMouseOver={() => setCodeHover(true)}
                            onMouseLeave={() => setCodeHover(false)}
                            onClick={() => {
                                setCodeShow(!codeShow)
                                if (isMobile) {
                                    setCodeHover(false);
                                }
                            }
                        }>
                            <div className="flex flex-row">
                                <h3 className="font-medium text-xl md:text-2xl text-left">Trenger du hjelp til kode?</h3>
                                <div className="pt-2 pl-4">
                                    <ArrowTopDown color={codeHover ? "#FBF5C1" : "#1C1B1F"}/> 
                                </div>
                            </div>
                        </button>      
                    </div>
                    {codeShow && (
                        <div className="flex flex-col pt-8">
                            <h3 className="font-bold text-xl md:text-2xl">Sølve Sthør</h3>
                            <div className="font-medium text-xl md:text-2xl underline">solve.sthor@vikoder.no</div>
                            <div className="font-medium text-xl md:text-2xl">458 01 153</div>
                        </div>
                    )}
                </div>
            </div>  
        </div>

    );
};

export default ContactUs;