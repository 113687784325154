import { useState } from "react";
import {isMobile} from 'react-device-detect';
import NTDefault from "../../Images/NTDefault.png"
import NTExpanded from "../../Images/NTExpanded.png"
import MTExpanded from "../../Images/MTExpanded.png"
import MTDefault from "../../Images/MTDefault.png"


const Customers = () => {
    const [tippingHover, setTippingHover] = useState(false);
    const [matHover, setMatHover] = useState(false);
    return (
        <div className="mt-16 mb-6">
            <h2 className="pt-2 font-medium text-3xl md:text-5xl">Hvem jobber vi med?</h2>
            <div className="mt-6 flex flex-col md:flex-row">
                <div>
                    <img loading="lazy" onMouseOver={() => setTippingHover(true)} onMouseLeave={() => setTippingHover(false)} src={tippingHover || isMobile ? NTExpanded : NTDefault} alt="Eivind sin kunde, Norsk Tipping" />
                </div>
                <div>
                    <img loading="lazy" onMouseOver={() => setMatHover(true)} onMouseLeave={() => setMatHover(false)} src={matHover || isMobile ? MTExpanded : MTDefault} alt="Sølve og Anders sin kunde, Mattilsynet" />
                </div>
            </div>
        </div>
        
        
       
    );
};

export default Customers;