import { useState } from "react";
import hvem from "../../Images/photos/hvem.jpg"
import robotarm from "../../Images/photos/robotarm.png"
import server from "../../Images/photos/server.png"

const AboutVikoder = () => {
    const [imgHover, setImgHover] = useState(false);
    return (
        <div className="mt-16 flex flex-col md:flex-row md:max-h-[900px]">
            <div className="flex flex-col justify-center">
                <div className="md:p-12">
                    <img loading="lazy" src={hvem} alt="Sølve, Eivind og Anders jobber" />
                </div>
            </div>
            <div>
                <div className="flex flex-col mr-4">
                    <div className="pb-6">
                        <h2 className="pt-2 font-medium text-3xl md:text-5xl">Hvem er vi?</h2>
                        <div className="font-medium text-xl md:text-2xl">
                            <p className="pt-4">
                                <span className="font-bold">VIKODER</span> er Anders, Eivind og Sølve: Et nytt og lite konsulentselskap i Hamar med tung kompetanse innenfor koding av nettsider. 
                            </p>
                            <p className="pt-4">
                                Vi liker å bygge ting. Ikke bare vårt eget selskap og fremtid – Sølve har også bygget sin egen server for eksempel.
                            </p>
                            <p className="pt-4">
                                ...og robotarm. 
                            </p>
                        </div>
                    </div>
                    <div className="max-h-[490px]">
                        <img loading="lazy" className="object-contain" onMouseOver={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)} src={imgHover ? server : robotarm} alt={imgHover ? "En server" : "En robotarm"} />
                    </div>
                </div>
            </div>
        </div>
        
       
    );
};

export default AboutVikoder;