import { useState } from "react";
import {isMobile} from 'react-device-detect';
import BehindTheScenes1Big from "../../Images/BehindScenes1Big.png"
import BehindTheScenes2Big from "../../Images/BehindScenes2Big.png"
import ArrowRight from "../../Images/ArrowRight";

const Door = () => {
    const [imgHover, setImgHover] = useState(false);
    const [showImg, setShowImg] = useState(false);
    const [doorHover, setDoorHover] = useState(false);

    const doorHandler = () => {
        if (!showImg && isMobile) {
            setTimeout(() => {
                setImgHover(true)
                setTimeout(() => {
                    setImgHover(false)
                  }, "2000");
              }, "1500");
        }
        setShowImg(!showImg)
    }

    return (
        <div>
            <div className={`flex justify-end duration-200 ${showImg ? "-translate-x-[600px]" : "" }`}>
                <div className="w-[550px] h-[450px] bg-primary-yellow max-[430px]:h-[370px] absolute"></div>
            </div>
            <div className="mt-4 flex justify-end">
                <div className="pt-1 max-w-lg border-primary-black border-2">
                    <div className="m-6" onMouseOver={() => setImgHover(true)} onMouseLeave={() => setImgHover(false)} >
                        <img className="overflow-hidden" src={imgHover ? BehindTheScenes2Big : BehindTheScenes1Big} alt="tre programmerere som koder" />     
                    </div>
                </div>
            </div>
            <div className={`mt-16 md:mt-12 flex justify-end duration-200 ${showImg ? "md:-translate-x-[600px]" : "" }`} >
                <button
                onMouseOver={() => setDoorHover(true)}
                onMouseLeave={() => setDoorHover(false)}
                onClick={() => {
                    doorHandler();
                    setDoorHover(false)
                    }
                }>
                    <div className={`flex flex-row pl-2 mt-6 md:mt-0 ${doorHover ? "bg-primary-black text-primary-yellow" : "" }`}>
                        <div className="font-medium text-2xl pr-2">{showImg ? "Husk å lukke døren" : "Hei! Vi er bak her."}</div>
                        <div className="pt-1.5 pr-2">
                            <ArrowRight color={doorHover ? "#FBF5C1" : "#000000"} />
                        </div>
                    </div>
                </button>
                
            </div>
        </div>
       
    );
};

export default Door;