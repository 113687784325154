
    import React from 'react';

    const ArrowRight = ({color = "#1C1B1F"}) => (
        <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9998 19.3332L16.4332 17.7776L23.0999 11.1109H0.666504V8.88874H23.0999L16.4443 2.22204L17.9998 0.666504L27.3332 9.99984L17.9998 19.3332Z" fill={color}/>
        </svg>
    );

    export default ArrowRight;
