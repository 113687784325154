import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer/Footer";

function Template() {
    return (
     <div>
        <TopBar />
        <div className="flex justify-center flex-row ">
            <div className="mt-5 md:w-[1200px]">
                <Outlet />
            </div>
        </div>
        <Footer />
     </div>
    );
  }
  
  export default Template;
  